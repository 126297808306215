.container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
  text-align: left;
  align-items: flex-end;
  position: relative;

  &::after {
    content: '';
    bottom: -10px;
    background: url('../assets/line.png');
    background-size: calc(100% - 24px);
    background-repeat: no-repeat;
    width: calc(100% - 24px);
    height: 20px;
    position: absolute;
  }

}

.logo {
  position: absolute;
  width: 400px;
  left: calc(50% - 200px);
  bottom: -150px;
  z-index: 3;
}

@media only screen and (max-width: 1000px) {
  .container {
    justify-content: center;
  &::after {
    display: none;
  }
}

  .logo {
    position: relative;
    width: 300px;
    left: inherit;
    bottom: inherit;
  }
}
