@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Trispace:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap');
@import './styles/equalizer.scss';
@import './styles/glitch.scss';

@font-face {
  font-family: "IBMPlexSans";
  src: url("./assets/IBMPlexSans-SemiBold.ttf");
  }
  
* {
  font-family: 'IBMPlexSans', sans-serif;
  box-sizing: border-box;
}

@media only screen and (max-width: 1000px) {
  .hide-sm {
    display: none;
  }
}
