.container {
  &:first-of-type {
    justify-content: space-between;
  }

  text-align: center;
  max-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  position: relative;
  max-width: 100vw;
}

.background {
  background: url('../assets/background.png');
  background-size: 100% auto;
  background-position: center;
  height: 100vh;
  background-color: #000;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

.discord-background {
  width: 80vw;
  position: relative;
  top: 80px;
}

.partnership {
  position: relative;
  justify-content: space-between;
  padding: 0 100px;
  flex-direction: row;

  &::after,
  &::before {
    content: '';
    bottom: 0;
    background: url('../assets/line.png');
    background-size: calc(100% - 24px);
    background-repeat: no-repeat;
    width: calc(100% - 24px);
    height: 20px;
    position: absolute;
    left: 20px;
  }

  &::after {
    transform: rotate(180deg);
    left: 0;
  }

  &::before {
    bottom: inherit;
    top: 0;
  }
}

.characters {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  position: absolute;
  bottom: -100px;
  width: 60vw;
  left: calc(50% - 30vw);
  z-index: 3;

  img {
    width: 100%;
    height: auto;
    border: 2px solid #1ce410;
  }
}

.lemon-game-logo {
  z-index: 1;
  width: 150px;
  position: absolute;
  bottom: -75px;
  left: calc(50% - 75px);
}

.nft {
  margin: 100px 10px 100px 0;
  height: 150px;
  object-fit: contain;
  position: relative;

  &:nth-of-type(1) {
    top: 60px;
  }

  &:nth-of-type(2) {
    bottom: 60px;
  }
}

.join-discord {
  background: url('../assets/discord.png');
  background-size: 100% 100%;
  text-align: left;
  font-weight: 700;
  font-size: 16px;
  line-height: 64px;
  height: 64px;
  max-width: 300px;
  width: 100%;
  padding: 20px 109px 20px 68px;
}

.powered-by-container {
  @extend .container;
  flex-direction: column;
  padding: 100px 120px 150px;

  a {
    border: 1px solid #1ce410;
    padding: 2px 4px;
    line-height: 40px;
  }
}


.powered-by {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 70px;

  img {
    margin: 0 0 0 10px;
  }
}

@media only screen and (max-width: 1000px) {
  .container:first-of-type {
    justify-content: center;
  }

  .background {
    background-size: auto 84%;
    background-position: 44% 0;
  }

  .characters {
    bottom: 10px;
    width: calc(100% - 10px);
    left: 5px;
  }

  .lemon-game-logo {
    width: 100px;
    left: calc(50% - 50px);
    bottom: -35px;
  }

  .partnership,
  .powered-by {
    justify-content: center;
    padding: 40px 20px;
  }

  .powered-by-container {
    padding: 0 0 100px;
  }

  .sponsors-container {
    flex-direction: column;
    margin: 0 0 20px;
  }

  .powered-by {
    flex-direction: column;
    margin-bottom: 0;

    img {
      margin: 0;
    }
  }
}
