.container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 70px;
}

.fade {
  position: relative;
  width: 100%;
  min-height: 60vh;
  top: -25px;
  background-image: linear-gradient(0deg, transparent, black 75%);
  z-index: 1;
}

.star-wars {
  display: flex;
  justify-content: center;
  position: relative;
  height: 800px;
  perspective: 600px;
  text-align: justify;
}

.paragraph {
  font-size: 80px;
  font-weight: bold;
  letter-spacing: 6px;
  line-height: 150%;
}

.crawl {
  position: relative;
  top: 99999px;
  transform-origin: 50% 100%;
}

.animated {
  animation: crawl infinite;
  animation-duration: 40s;
  animation-timing-function: linear;
}

.story {
  position: absolute;
  top: 50%;
  left: 10%;
  font-size: 20px;
  z-index: 3;
  font-size: 30px;

  button {
    margin-right: 30px;
    border: 1px solid #1ce410;
    padding: 2px 4px;
  }
}

.crawl>.title h1 {
  font-size: 10rem;
  margin: 0 0 100px;
  text-align: center;
}

@keyframes crawl {
  0% {
    top: -100px;
    transform: rotateX(20deg) translateZ(0);
  }

  100% {
    top: -5000px;
    transform: rotateX(25deg) translateZ(-2000px);
  }
}

@media only screen and (max-width: 1000px) {
  .story {
    top: 20px;
  }

  .crawl>.title h1 {
    font-size: 45px;
  }

  .paragraph {
    font-size: 40px;
  }
}
