.container {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100vh;
  overflow: hidden;

  p {
    font-size: 30px;
    line-height: 32px;
  }
}

.back {
  position: absolute;
  top: 3%;
  left: 3%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;

  span {
    border: 1px solid #1ce410;
    padding: 2px 4px;
    line-height: 40px;
  }
}

.background {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: url('../assets/background.png');
    background-size: 100% auto;
    background-position: center;
    height: 100vh;
    opacity: 0.5;
    background-color: #000;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    filter: grayscale(100%);
  }

  &::after {
    content: '';
    position: absolute;
    top: -500px;
    right: -500px;
    bottom: -500px;
    left: -500px;
    background: transparent url(https://www.dropbox.com/s/h7ab1c82ctzy83n/noise.png?raw=1) 0 0;
    background-size: 320px 320px;
    opacity: .20;
    animation: noise 5s steps(8, end) infinite both;
  }
}


@keyframes noise {
  0% {
    transform: translateX(0px, 0px);
  }

  10% {
    transform: translate(-100px, 100px);
  }

  20% {
    transform: translate(150px, -100px);
  }

  30% {
    transform: translate(-100px, 100px);
  }

  40% {
    transform: translate(100px, -150px);
  }

  50% {
    transform: translate(-100px, 200px);
  }

  60% {
    transform: translate(-200px, -100px);
  }

  70% {
    transform: translateY(50px, 100px);
  }

  80% {
    transform: translate(100px, -150px);
  }

  90% {
    transform: translate(0px, 200px);
  }

  100% {
    transform: translate(-100px, 100px);
  }
}

.sponsors-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 50px 0 100px;
  padding: 20px 20px;
}

.sponsor {
  height: 100px;
  max-width: 200px;
  margin: 0 30px;
}

.powered-by {
  @extend .container;
  flex-direction: row;
  height: initial;
  align-items: center;
  padding: 100px 0 0;

  img {
    margin-left: 100px;
  }
}


@media only screen and (max-width: 1000px) {
  .container {
    padding: 10px;

    p {
      font-size: 16px;
      line-height: 18px;
    }
  }

  .background::before {
    background-size: auto 84%;
    background-position: 44% 0;
  }

  .powered-by {
    justify-content: center;
  }

  .sponsors-container {
    flex-direction: column;
    margin: 0 0 20px;
  }

  .powered-by {
    flex-direction: column;

    img {
      margin: 0;
    }
  }
}
